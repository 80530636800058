<template>
    <form
        class="code-page"
        novalidate
        @submit.prevent="validate"
    >
        <Title
            class="code-page__title"
        >
            Проверка и подпись
        </Title>
        <p class="code-page__desc">
            Скачайте и ознакомьтесь с <a href="#">договором страхования и заявлением на включение в список застрахованных лиц</a>.<br/>
            Затем нажмите кнопку «Подписаться» и введите в появившееся окно код из СМС, отправленный
            <span class="code-page__number">на номер <strong>{{ user.contactData.phone | mask('+# ### #######') }}</strong></span>.
        </p>
        <h2
            class="code-page__subtitle"
        >
            Введите код из СМС для подписания
        </h2>
        <Code
            ref="code"
            type="before"
            :getCode="getSignCode"
            @ready="submit"
        />
        <div
            class="code-page__actions"
        >
            <Button
                type="submit"
            >
                Подписать
            </Button>
            <Button
                back
                @click.native="back"
            >
                Назад
            </Button>
        </div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex'
import mask from '@index/helpers/string/setMask'

import getSignCode from '@index/api/getSignCode'
import sendCard from '@index/api/sendCard'
import sendSignCode from '@index/api/sendSignCode'

import openRoute from '@index/helpers/router/openRoute'

import Code from '@index/components/common/code/Code'
import Title from '@index/components/gui/title/Title'
import Button from '@index/components/gui/button/Button'

import store from '@index/store'

import './code-page.scss'

export default {
    computed: {
        ...mapGetters({
            user: ['application/user'],
            code_hash: ['application/code']
        })
    },
    methods: {
        back() {
            this.$router.push({ name: 'PersonalInfo' })
        },
        getSignCode() {
            getSignCode({
                contactData: this.user.contactData
            })
        },
        validate() {
            this.$refs.code.validate()

            this.$refs.code.isValid && this.submit()
        },
        async submit() {
            // @TODO почти нигде не обрабатываем ошибки
            const { wrongCode, next_step } = await sendSignCode({
                contactData: {
                    ...this.user.contactData,
                    code: this.$refs.code.form.code,
                    code_hash: this.code_hash
                }
            })

            if (wrongCode) {
                this.$refs.code.validate();
                this.$refs.code.formErrors.code = 'Неверный код'
                return
            }

            this.$store.commit('application/clearCode')
            this.$refs.code.resetTimer()
            if (!this.$route.params.cardInfo) {
                openRoute(next_step)
            } else {
                const card = {
                    card: this.$route.params.cardInfo.number,
                    date: this.$route.params.cardInfo.date,
                    cvv: this.$route.params.cardInfo.cvv,
                    name: this.$route.params.cardInfo.holder_name
                }

                // @TODO наверняка можно улучшить и вынести сохранение карты в lite
                const data = await sendCard(card);

                this.$router.push({
                    name: 'CardSecure',
                    params: {
                        data,
                        card
                    }
                })
            }
        }
    },
    components: {
        Code,
        Title,
        Button
    },
    filters: {
        mask
    },
    async beforeRouteEnter(to, from, next) {
        const { passportData: { passportnumber } } = store.getters['application/user']

        if (passportnumber) {
            if (from.name !== null) {
                await store.dispatch('application/update')
            }

            const {
                isSigned
            } = store.getters['application/user']

            if (isSigned) {
                store.commit('application/load', false)
                next({
                    name: 'Card'
                })
            } else {
                next()
            }
        } else {
            store.commit('application/load', false)
            next({ name: 'PersonalInfo' })
        }
    }
}
</script>